import { useState } from "react";
import { collection, doc, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { useAuth, useFirestore, useFirestoreCollectionData } from "reactfire";
import { Button, Card, CardTitle, Col, Container, Row, Spinner, Table } from "reactstrap";
import { loadStripe } from '@stripe/stripe-js';
import { closeSnackbar, SnackbarKey, useSnackbar } from "notistack";

export default function AdminBilling() {
  const auth = useAuth();
  const firestore = useFirestore();
  const snack = useSnackbar();

  const [inProgress, setInProgress] = useState(false);

  const subscriptionsQuery = query(collection(firestore, 'stripe-customers', auth.currentUser?.uid!, 'subscriptions'), where('status', 'in', ['trialing', 'active']));
  const {status: subscriptionsStatus, data: subscriptions} = useFirestoreCollectionData(subscriptionsQuery);

  const snackAction = (snackbarId: SnackbarKey) => (
    <>
      <Button color="light" size="sm" onClick={() => { closeSnackbar(snackbarId) }}>
        Dismiss
      </Button>
    </>
  );

  const handleSubscribe = async (plan: any) => {
    setInProgress(true);

    const checkoutSessionDocRef = doc(collection(firestore, 'stripe-customers', auth.currentUser?.uid!, 'checkout_sessions'));
    await setDoc(checkoutSessionDocRef, {
      price: plan.price,
      allow_promotion_codes: true,
      success_url: window.location.origin + '/billing',
      cancel_url: window.location.origin + '/billing'
    });
    onSnapshot(checkoutSessionDocRef, async (snap) => {
      if (snap.data()) {
        const sessionId = snap.data()?.sessionId;
        const error = snap.data()?.error;
        if (error && error.message) {
          console.error(error.message);
          snack.enqueueSnackbar(error.message, {variant: 'error', persist: true, action: snackAction});
          setInProgress(false);
        } else if (sessionId) {
          // We have a session, let's redirect to Checkout
          const stripe = await loadStripe('pk_live_51M6rVrDVffHhx8G4Rx72syOI5gO3TQedwLEPRTOSe3ehZ981W3aeBRxvadnJ0jgcP84GlCq6CfJd9490M89zu61X005SYU37Ln');
          if (stripe) stripe.redirectToCheckout({ sessionId });
        }
      }
    });
  };

  return (
    <Container>
      <h1 className='mt-5'>Subscription</h1>
      <hr />
      <Container>
        <Row xs='2' sm='3'>
          <Col>
            <Card color='dark' body outline={true} className="shadow">
              <CardTitle className="text-center"><h1>Free Plan - €0/year</h1></CardTitle>
              <p className="lead text-center">Best for Developer with private products</p>
              <ul>
                <li>10 Products</li>
                <li>1 Organization</li>
                <li>1 User</li>
                <li>Web API</li>
                <li>Community support</li>
              </ul>
              <Button color="primary" disabled>{subscriptionsStatus === 'success' && subscriptions.length === 0 ? 'Current Plan' : 'Unavailable'}</Button>
            </Card>
          </Col>
          <Col>
            <Card color='light' body className="shadow">
              <CardTitle className="text-center"><h1>Freelancer Plan - €10/year</h1></CardTitle>
              <p className="lead text-center">Best for Freelancers with multiple products</p>
              <ul>
                <li>100 Products</li>
                <li>10 Organization</li>
                <li>1 User</li>
                <li>Web API</li>
                <li>Professional 9/5 support</li>
              </ul>
              <Button color="primary" disabled={inProgress} onClick={() => handleSubscribe({id: 'freelancer', price: 'price_1M7Z9ADVffHhx8G4Ghmyqm8W'})}>Upgrade</Button>
            </Card>
          </Col>
          <Col>
            <Card color='light' body className="shadow">
              <CardTitle className="text-center"><h1>Pro Plan - €20/year</h1></CardTitle>
              <p className="lead text-center">Best for Business with many users and products</p>
              <ul>
                <li>Unlimited Products</li>
                <li>1 Organization</li>
                <li>Unlimited Users</li>
                <li>Web API</li>
                <li>Professional 9/5 support</li>
              </ul>
              <Button color="primary" disabled={inProgress} onClick={() => handleSubscribe({id: 'pro', price: 'price_1M7KzzDVffHhx8G4dH6bbP2s'})}>Upgrade</Button>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container>
        <h2 className="mt-5">Current subscription</h2>
        <hr />
        {subscriptionsStatus === 'loading' && <Spinner>Loading subscriptions...</Spinner>}
        {subscriptionsStatus === 'success' && subscriptions.length === 0 && <p className="lead">You are on the Free Plan. Need more? Click Upgrade</p>}
        {subscriptionsStatus === 'success' && subscriptions.length > 0 && <Table>
          <thead>
          <tr>
            <th>Date subscribed</th>
          </tr>
          </thead>
          <tbody></tbody>
        </Table>}
      </Container>
    </Container>
  );
}
