import { useRouteError } from "react-router-dom";
import { Alert, Container } from "reactstrap";

export default function ErrorPage() {
  const error = useRouteError() as Error;
  console.error(error);

  return (
    <Container className="mt-5 text-center" id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <Alert color="danger">
        <i>{error.message}</i>
      </Alert>
    </Container>
  );
}
