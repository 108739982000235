import { collection, query, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { useAuth, useFirestore, useFirestoreCollectionData, useFunctions } from "reactfire";
import { Alert, Button, Container, Spinner, Table } from "reactstrap";
import buildNumberTypes from "../models/build-number-types";

export default function ProductList() {
  const auth = useAuth();
  const firestore = useFirestore();
  const functions = useFunctions();
  const snack = useSnackbar();

  const {status: productsStatus, data: products} = useFirestoreCollectionData(query(collection(firestore, 'products'), where("userId", "==", auth.currentUser?.uid)));
  
  const handleIncrementBuildNumberClick = (event: React.FormEvent<HTMLButtonElement>, productId: string) => {
    event.preventDefault();
    const incrementBuildNumber = httpsCallable(functions, "incrementBuildNumber");
    incrementBuildNumber({
      productId
    }).then((result: any) => {
      snack.enqueueSnackbar('Build number has been increased successfully to ' + result.data, {variant: 'success'});
    }).catch((error) => {
      snack.enqueueSnackbar('Failed to increase Build number: ' + error.message, {variant: 'error'});
    }).finally(() => {
    });
  }

  if (productsStatus === 'loading') {
    return (<Container className='text-center'><Spinner>Loading Products...</Spinner></Container>);
  } else if (productsStatus === 'error') {
    return (<Container className='text-center'><Alert color="danger">Failed to retrieve Products data</Alert></Container>);
  } else {
    return (
      <>
        {products.length > 0 && <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Build number type</th>
              <th>Last Build Number</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.length !== 0 && products.map(p => <tr key={p.id}>
              <td><Link to={'/products/' + p.id}>{p.name}</Link></td>
              <td>{buildNumberTypes[p.buildNumberType].name}</td>
              <td>{p.buildNumberLastValue}</td>
              <td><Button color="light" size="sm" onClick={(e) => handleIncrementBuildNumberClick(e, p.id)}><i className="bi bi-plus-square"></i></Button></td>
            </tr>)}
          </tbody>
        </Table>}
        {products.length === 0 && <Container className='text-center'><Alert color='light' fade={false}>You do not have any Products yet. To create the first one, click the Add new Product button above.</Alert></Container>}
      </>
    );
  }
}
