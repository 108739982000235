import React from 'react';
import { setUserId } from 'firebase/analytics';
import { logEvent } from 'firebase/analytics';
import { sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';
import { useAnalytics, useAuth } from 'reactfire';
import { Alert, Button, Container, Form, FormGroup, Input, Label, Spinner } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Login(props: any) {
  const analytics = useAnalytics();
  const auth = useAuth();
  const navigate = useNavigate();
  let location = useLocation();
  let from = location.state?.from?.pathname || "/";

  const usernameRef = React.useRef<HTMLInputElement>(null);
  const passwordRef = React.useRef<HTMLInputElement>(null);
  const [message, setMessage] = React.useState<string | null>(null);
  const [error, setError] = React.useState<string | null>(null);
  const [signInButtonDisabled, setSignInButtonDisabled] = React.useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSignInButtonDisabled(true);
    setError(null);
    setMessage(null);
    signInWithEmailAndPassword(auth, usernameRef.current?.value!, passwordRef.current?.value!).then((credential) => {
      console.debug("Login success", credential);
      if (analytics && credential.user?.uid) {
        setUserId(analytics, credential.user?.uid);
        logEvent(analytics, 'login', {method: 'email'});
      }
      navigate(from, { replace: true });
    }).catch((error) => {
      let errorMessage = error.message;
      if (error.code === 'auth/wrong-password') {
        errorMessage = 'Invalid username and/or password';
      } else if (error.code === 'auth/user-not-found'){
        errorMessage = 'User not found with the provided email address. Would you like to register?';
      } else if (error.code === 'auth/user-disabled'){
        errorMessage = 'The account is disabled';
      } else if (error.code === 'auth/invalid-email'){
        errorMessage = 'Invalid email address';
      } else if (error.code === 'auth/network-request-failed') {
        errorMessage = 'Unable to connect. Are you offline?';
      } else {
        console.error(error);
        if (analytics) logEvent(analytics, 'exception', {fatal: false, description: error.message});
      }
      setError(errorMessage);
    }).finally(() => {
      setSignInButtonDisabled(false);
    });
  };

  const handleResendPasswordClick = (event: React.MouseEvent) => {
    event.preventDefault();
    setError(null);
    setMessage(null);
    sendPasswordResetEmail(auth, usernameRef.current?.value!).then(() => {
      setMessage('Check your mailbox for next steps');
    }).catch((error) => {
      let errorMessage = error.message;
      if (error.code === 'auth/user-not-found') {
        errorMessage = 'User not found with the provided email address. Would you like to register?';
      } else if (error.code === 'auth/invalid-email') {
        errorMessage = 'Invalid email address';
      } else if (error.code === 'auth/missing-email') {
        errorMessage = 'Invalid email address';
      } else if (error.code === 'auth/network-request-failed') {
        errorMessage = 'Unable to connect. Are you offline?';
      } 
      setError(errorMessage);
      if (analytics) logEvent(analytics, "exception", {fatal: false, description: error.message});
    });
  };

  return (
    <Container>
      <div className="col-md-10 offset-md-1 col-lg-6 offset-lg-3 mt-5 text-center">
        <h1 className="display-5">NextBuildNumber</h1>
        <h1>Sign in</h1>
        {error && <Alert color='danger'>{error}</Alert>}
        {message && <Alert>{message}</Alert>}
        <Form id='accountForm' onSubmit={handleSubmit}>
          <FormGroup floating>
            <Input id='email' name='email' type='email' placeholder='Email' autoComplete='username' innerRef={usernameRef} required />
            <Label for='email'>Email</Label>
          </FormGroup>
          <FormGroup floating>
            <Input id='password' name='password' type='password' placeholder='Password' autoComplete='current-password' innerRef={passwordRef} required />
            <Label for='password'>Password</Label>
          </FormGroup>
          <Button type='submit' color="primary" size="lg" disabled={signInButtonDisabled}>{signInButtonDisabled ? <Spinner></Spinner> : 'Sign in'}</Button>  
        </Form>
        <div className="text-center mt-3">
          <Button color='link' onClick={handleResendPasswordClick}>Forgot password?</Button> |
          <Button color='link' onClick={() => navigate('/signup')}>Not registered yet? Sign up</Button> |
          <Button color='link' onClick={() => navigate('/')}>Back to Home</Button>
        </div>
      </div>
    </Container>
  );
}