import { useState } from "react";
import { Link, Navigate, Outlet, useLocation } from "react-router-dom";
import { useSigninCheck } from "reactfire";
import { Collapse, Container, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Spinner } from "reactstrap";

export default function AdminRoot() {
  const location = useLocation();
  const { status: signinStatus, data: signinCheckResult } = useSigninCheck();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  if (signinStatus === 'loading') {
    return (<Container className='mt-5 text-center'><Spinner>Loading...</Spinner></Container>);
  } else if (signinStatus === 'success') {
    if (signinCheckResult?.signedIn && signinCheckResult.user != null) {
      return (
        <div>
          <Navbar expand='md' color='dark' dark>
            <NavbarBrand href='/'>NextBuildNumber</NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
              <Nav className="ms-auto" navbar>
                <NavItem>
                  <NavLink tag={Link} to={"/products"}>Products</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to={"/settings"}>Settings</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to={"/billing"}>Billing</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="https://docs.nextbuildnumber.net/" target="_blank">Documentation</NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>
          <div id="detail"><Outlet /></div>
        </div>
      );
    } else {
      return (<Navigate to="/signin" state={{ from: location }} replace />);
    }
  } else {
    return (<Container>{JSON.stringify(signinCheckResult)}</Container>);
  }
}
