import dateFormat from 'dateformat';

interface BuildNumberType {
  /**
   * Name of the Type
   */
  name: string;
  /**
   * Type can have an initial value defined. For example: 1
   */
  hasInitialValue: boolean;
  /**
   * Update interval of current value in seconds
   */
  updateInterval?: number;
  /**
   * Returns the current generated value or a default initial value if hasInitialValue is true
   */
  getCurrentValue(): number;
};

const BuildNumberAutoIncrement: BuildNumberType = {
  name: 'Auto-increment decimal number',
  hasInitialValue: true,
  getCurrentValue: (): number => {
    return 1;
  },
};

/**
 * https://github.com/prantlf/build-number-generator
 */
const BuildNumberBcd: BuildNumberType = {
  name: 'BCD-Notation YYYYMMDDCCC',
  hasInitialValue: false,
  updateInterval: 120,
  getCurrentValue: (): number => {
    // Count 2-minute intervals elapsed since midnight:(HH * 60 + MM) / 2
    const now = new Date();
    const counter = Math.floor((now.getUTCHours() * 60 + now.getUTCMinutes()) / 2);
    return parseInt(dateFormat(now, 'UTC:yymmdd') + (counter > 99 ? `${counter}` : counter > 9 ? `0${counter}` : `00${counter}`));
  }
};

const BuildNumberEpoch: BuildNumberType = {
  name: 'Unix Epoch',
  hasInitialValue: false,
  updateInterval: 1,
  getCurrentValue: (): number => {
    const now = new Date();
    const utcMilllisecondsSinceEpoch = now.getTime() + (now.getTimezoneOffset() * 60 * 1000);
    const utcSecondsSinceEpoch = Math.round(utcMilllisecondsSinceEpoch / 1000);
    return utcSecondsSinceEpoch;
  }
};

interface BuildNumberTypes {
  [index: string]: BuildNumberType;
};

const buildNumberTypes: BuildNumberTypes = {
  "autoincrement": BuildNumberAutoIncrement,
  "yymmddccc": BuildNumberBcd,
  "epoch": BuildNumberEpoch,
};

export default buildNumberTypes;
