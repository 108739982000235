import { logEvent, setUserId } from "firebase/analytics";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAnalytics, useAuth } from "reactfire";
import { Alert, Button, Container, Form, FormGroup, Input, Label, Spinner } from "reactstrap";


export default function SignUp() {
  const analytics = useAnalytics();
  const auth = useAuth();
  const navigate = useNavigate();
  
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [signUpButtonDisabled, setSignUpButtonDisabled] = useState(false);

  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const handleAccountFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSignUpButtonDisabled(true);
    setError(null);
    setMessage(null);
    
    createUserWithEmailAndPassword(auth, emailRef.current?.value!, passwordRef.current?.value!).then(async (credential) => {
      // Update profile
      await updateProfile(credential.user, {displayName: nameRef.current?.value});
      // Analytics
      if (analytics && credential.user?.uid) {
        setUserId(auth, credential.user?.uid);
        logEvent(analytics, "sign_up", {method: "email"});
      }
      // Navigate to Profile page
      navigate("/settings", {replace: true});
    }).catch((error) => {
      let errorMessage = error.message;
      if (error.code === 'auth/email-already-in-use') {
        errorMessage = 'We already have an account with this email address';
      } else if (error.code === 'auth/invalid-email') {
        errorMessage = 'Invalid email address';
      } else if (error.code === 'auth/weak-password') {
        errorMessage = 'This password is too weak';
      } else if (error.code === 'auth/network-request-failed') {
        errorMessage = 'Unable to connect. Are you offline?';
      }
      setError(errorMessage);
      if (analytics) logEvent(analytics, "exception", {fatal: false, description: error.message});
    }).finally(() => {
      setSignUpButtonDisabled(false);
    });
  };

  return (<>
    <Container fluid>
      <div className="col-md-10 offset-md-1 col-lg-6 offset-lg-3 mt-5 text-center">
        <h1 className="display-5">NextBuildNumber</h1>
        <h1>Sign up</h1>
        {error && <Alert color='danger'>{error}</Alert>}
        {message && <Alert>{message}</Alert>}
        <Form id='accountForm' onSubmit={handleAccountFormSubmit}>
          <FormGroup floating>
            <Input id='name' placeholder='Name' autoComplete='fullname' innerRef={nameRef} autoFocus />
            <Label for='name'>Name</Label>
          </FormGroup>
          <FormGroup floating>
            <Input id='email' name='email' type='email' placeholder='Email' autoComplete='username' innerRef={emailRef} required />
            <Label for='email'>Email</Label>
          </FormGroup>
          <FormGroup floating>
            <Input id='password' name='password' type='password' placeholder='Password' autoComplete='current-password' innerRef={passwordRef} required />
            <Label for='password'>Password</Label>
          </FormGroup>
          <div>
            <Button type='submit' color="primary" size="lg" disabled={signUpButtonDisabled}>{signUpButtonDisabled ? <Spinner></Spinner> : 'Sign up'}</Button>
          </div>
        </Form>
        <div className="text-center mt-3">
          <Button color='link' onClick={() => navigate('/signin')}>Already have an account? Sign in</Button> |
          <Button color='link' onClick={() => navigate('/')}>Back to Home</Button>
        </div>
      </div>
    </Container>
  </>);
}
