import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSigninCheck } from "reactfire";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Button, Card, CardTitle, Col, Collapse, Container, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Row } from "reactstrap";

export default function Home() {
  const {status: signInStatus, data: signInCheckResult} = useSigninCheck();
  const navigate = useNavigate();

  const [isNavOpen, setIsNavOpen] = useState(false);
  const [openFaqAccordion, setOpenFaqAccordion] = useState('');
  const toggleFaqAccordion = (id: string) => {
    if (openFaqAccordion === id) {
      setOpenFaqAccordion('');
    } else {
      setOpenFaqAccordion(id);
    }
  };

  const toggleNav = () => setIsNavOpen(!isNavOpen);

  return (
    <>
      <Navbar expand='md' color='dark' dark fixed="top">
        <NavbarBrand href='/'>NextBuildNumber</NavbarBrand>
        <NavbarToggler onClick={toggleNav} />
        <Collapse isOpen={isNavOpen} navbar>
          <Nav className="ms-auto" navbar>
            <NavItem>
              <NavLink tag={Link} to={"/"}>Features</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to={"#pricing"}>Pricing</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://docs.nextbuildnumber.net/" target="_blank">Documentation</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to={"/products"}>My Products</NavLink>
            </NavItem>
            {signInStatus==='success' && !signInCheckResult.signedIn && <NavItem><NavLink tag={Link} to={"/signup"}>Sign up</NavLink></NavItem>}
            {signInStatus==='success' && !signInCheckResult.signedIn && <NavItem><NavLink tag={Link} to={"/signin"}>Sign in</NavLink></NavItem>}
            {signInStatus==='success' && signInCheckResult.signedIn && <NavItem><NavLink tag={Link} to={"/settings"}><i className="bi bi-person-circle"></i></NavLink></NavItem>}
          </Nav>
        </Collapse>
      </Navbar>
      <Container id="features">
        <Row className="pt-5 align-items-center border-bottom">
          <Col xs="12" sm="4">
            <img src="/images/unique-numbers-heart.webp" className="rounded-3 w-100" alt="Unique numbers and heart"></img>
          </Col>
          <Col xs="12" sm="8">
            <h2>Unique build numbers accross branches?<br/>Possible</h2>
            <p>We generate build numbers for your CI/CD pipeline's release job. Build numbers are unique on Product level, so you can use them across your branches.</p>
          </Col>
        </Row>
        <Row className="pt-5 align-items-center border-bottom">
          <Col xs={{size: 12, order: 2}} sm={{size: 8, order: 1}} className="text-xs-start text-sm-end">
            <h2>Sequential build numbers accross CI tools?<br/>Possible</h2>
            <p>Use our REST API service to increment and fetch your unique build numbers, regardless of your CI/CD system, incl. GitLab CI, GitHub Actions, Jenkins, TeamCity, BitBucket, Azure DevOps, etc.</p>
          </Col>
          <Col xs={{size: 12, order: 1}} sm={{size: 4, order: 2}}>
            <img src="/images/unique-numbers-star.webp" className="rounded-3 w-100" alt="Unique numbers and Star"></img>
          </Col>
        </Row>
      </Container>
      <Container id="pricing" className="text-center mt-5">
        <h1>Pricing</h1>
        <p className="lead">No credit card required to get started.</p>
      </Container>
      <Container>
        <Row xs='1' sm='2' md='3'>
          <Col>
            <Card color='dark' body outline={true} className="shadow mt-2">
              <CardTitle className="text-center"><h1>Free Plan - €0/year</h1></CardTitle>
              <p className="lead text-center">Best for Developer with private products</p>
              <ul>
                <li>10 Products</li>
                <li>1 Organization</li>
                <li>1 User</li>
                <li>Web API</li>
                <li>Community support</li>
              </ul>
              <Button color="primary" disabled={signInStatus === 'success' && signInCheckResult.signedIn} onClick={() => navigate('/signup')}>
                {signInStatus === 'success' && signInCheckResult.signedIn ? 'Current Plan' : 'Get started for FREE'}
              </Button>
            </Card>
          </Col>
          <Col>
            <Card color='light' body className="shadow mt-2">
              <CardTitle className="text-center"><h1>Freelancer Plan - €10/year</h1></CardTitle>
              <p className="lead text-center">Best for Freelancers with multiple products</p>
              <ul>
                <li>100 Products</li>
                <li>10 Organization</li>
                <li>1 User</li>
                <li>Web API</li>
                <li>Professional 9/5 support</li>
              </ul>
              <Button color="primary" onClick={() => navigate(signInStatus === 'success' && signInCheckResult.signedIn ? 'billing' : '/signup?plan=freelancer')}>
                {signInStatus === 'success' && signInCheckResult.signedIn ? 'Upgrade' : 'Get started'}
              </Button>
            </Card>
          </Col>
          <Col>
            <Card color='light' body className="shadow mt-2">
              <CardTitle className="text-center"><h1>Pro Plan - €20/year</h1></CardTitle>
              <p className="lead text-center">Best for Business with many users and products</p>
              <ul>
                <li>Unlimited Products</li>
                <li>1 Organization</li>
                <li>Unlimited Users</li>
                <li>Web API</li>
                <li>Professional 9/5 support</li>
              </ul>
              <Button color="primary" onClick={() => navigate(signInStatus === 'success' && signInCheckResult.signedIn ? 'billing' : '/signup?plan=pro')}>
                {signInStatus === 'success' && signInCheckResult.signedIn ? 'Upgrade' : 'Get started'}
              </Button>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container className="text-center py-5">
        <h1>Frequently asked questions</h1>
      </Container>
      <Container>
        {// https://github.com/reactstrap/reactstrap/issues/2165
        //@ts-ignore
        <Accordion flush open={openFaqAccordion} toggle={toggleFaqAccordion}>
          <AccordionItem>
            <AccordionHeader targetId="q1">What is NextBuildNumber?</AccordionHeader>
            <AccordionBody accordionId="q1">NextBuildNumber.net is a SaaS providing unique build numbers for your software products.
            Build numbers are usually used in CI/CD pipelines to identify build jobs.<br/>
            NextBuildNumber provides REST API, and API client libraries and CLI to reduce the amount of code you need to write to use the API.</AccordionBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeader targetId="q2">Can I use NextBuildNumber for Free?</AccordionHeader>
            <AccordionBody accordionId="q2">Yes. Free Plan is automatically assigned to your account after signing up.
            You can generate unique build numbers for 10 software products with Free Plan.<br/>
            Need more? Upgrade to one of our commercial plans.</AccordionBody>
          </AccordionItem>
        </Accordion>}
      </Container>
      <Container className="text-center py-5">
        <h1>Ready for real unique build numbers?</h1>
        <Button color="light" size="lg" onClick={() => navigate(signInStatus === 'success' && signInCheckResult.signedIn ? 'products' : 'signup')}>
        {signInStatus === 'success' && signInCheckResult.signedIn ? 'Go to your Products' : 'Get started for free'}</Button>
      </Container>
      <footer className="container-fluid text-center py-5 bg-light">
        <Row>
          <Col>
          <p>&copy; Copyright 2022</p>
          </Col>
          <Col>
          <p>Privacy Policy | Terms of Use</p>
          </Col>
        </Row>
      </footer>
    </>
  );
}
