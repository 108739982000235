import { signOut, updateProfile } from "firebase/auth";
import { collection, deleteDoc, doc, orderBy, query, Timestamp, where } from "firebase/firestore";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useAuth, useFirestore, useFirestoreCollectionData, useFunctions, useUser } from "reactfire";
import { Alert, Button, Col, Container, Form, FormGroup, Input, Label, Row, Spinner, Table } from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { httpsCallable } from "firebase/functions";

export default function AdminSettings() {
  const auth = useAuth();
  const firestore = useFirestore();
  const functions = useFunctions();

  const snack = useSnackbar();

  const [fullName, setFullName] = useState(auth.currentUser?.displayName ?? '');
  const tokenNameRef = useRef<HTMLInputElement>(null);
  const tokenExpDateRef = useRef<HTMLInputElement>(null);
  const [tokenMessage, setTokenMessage] = useState<any>(null);
  const [tokenButtonDisabled, setTokenButtonDisabled] = useState(false);
  const [tokenButtonTitle, setTokenButtonTitle] = useState("Create access token");

  const {status: userStatus, data: userData} = useUser();
  const tokensCollection = collection(firestore, 'users', auth.currentUser?.uid!, 'tokens');
  const tokensQuery = query(tokensCollection, where('isRevoked', '==', false), orderBy("createdDate", "desc"));
  const { status: tokensStatus, data: tokensData} = useFirestoreCollectionData(tokensQuery, {
    // TODO: https://github.com/FirebaseExtended/reactfire/issues/485#issuecomment-1007031459
    // https://github.com/FirebaseExtended/reactfire/discussions/228
    // @ts-ignore
    idField: `id-${auth.currentUser.stsTokenManager.expirationTime}`,
  });

  useEffect(() => {
    setFullName(userData?.displayName ?? '');
  }, [userData]);

  const handleLogoutButtonOnClick = () => {
    signOut(auth);
  };

  const handleProfileFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (auth.currentUser) {
      updateProfile(auth.currentUser, {displayName: fullName}).then(() => {
        snack.enqueueSnackbar('Profile has been updated successfully', {variant: 'success'});
      }).catch(() => {
        snack.enqueueSnackbar('Failed to update your Profile', {variant: 'error'});
      });
    }
  };

  const handleAccessTokenFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setTokenMessage(null);
    setTokenButtonDisabled(true);
    setTokenButtonTitle("Creating access token...");

    const generateAccessToken = httpsCallable(functions, "generateAccessToken");
    generateAccessToken({
      "tokenName": tokenNameRef.current?.value,
      "tokenExpDate": tokenExpDateRef.current?.value,
    }).then((result: any) => {
      snack.enqueueSnackbar('New Access token has been created successfully', {variant: 'success'});
      setTokenMessage({level: "info", message: `Save your new Access token as it cannot be retrieved any more: ${result.data}`});
    }).catch((error) => {
      snack.enqueueSnackbar('Failed to create new Access token: ' + error.message, {variant: 'error'});
    }).finally(() => {
      setTokenButtonTitle("Create access token");
      setTokenButtonDisabled(false);
    });
  };

  const handleTokenDelete = (event: React.FormEvent<HTMLButtonElement>, tokenId: string) => {
    event.preventDefault();
    confirmAlert({
      title: 'Revoke',
      message: 'Are you sure to revoke this Access token?',
      buttons: [
        {
          label: 'Revoke',
          onClick: () => {
            const tokenRef = doc(collection(firestore, 'users', auth.currentUser?.uid!, 'tokens'), tokenId);
            deleteDoc(tokenRef).then(() => {
              snack.enqueueSnackbar('Access token has been deleted successfully', {variant: 'success'});
            }).catch((error) => {
              snack.enqueueSnackbar('Failed to delete Access token: ' + error.message, {variant: 'error'});
            });
          },
        },
        {
          label: 'Cancel',
        }
      ],
    });
  };

  return (
    <Container>
      <div className='hstack gap-3 mt-5'>
        <h1 className='me-auto'>Profile</h1>
        <Button onClick={handleLogoutButtonOnClick}><i className="bi bi-box-arrow-left"></i> Logout</Button>
      </div>
      <hr />
      <Container>
        <Row>
          <Col xs='12' sm='4'>
            <h4>Main settings</h4>
            <small>This information will appear on your profile.</small>
          </Col>
          <Col xs='12' sm='8'>
            <Form id='profileForm' onSubmit={handleProfileFormSubmit}>
              <FormGroup>
                <Label for='fullName'><b>Name</b></Label>
                <Input id='fullName' value={fullName} onChange={(event) => setFullName(event.target.value)} autoFocus />
              </FormGroup>
            </Form>
            <Button type='submit' form='profileForm' color='primary'><i className="bi bi-save"></i> Save</Button>
          </Col>
        </Row>
      </Container>

      <h1 className='me-auto mt-5'>Account</h1>
      <hr />
      <Container>
        <Row>
          <Col xs='12' sm='4'>
            <h4>Account details</h4>
          </Col>
          <Col xs='12' sm='8'>
            <Row>
              <Col sm='4'><b>User ID</b></Col>
              <Col sm='8'>
                {userStatus === 'loading' && <Spinner />}
                {userStatus === 'success' && userData != null && <span>{userData.uid}</span>}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs='12' sm={{offset: 4, size: 8}}>
            <Row>
              <Col sm='4'><b>Registration date</b></Col>
              <Col sm='8'>
                {userStatus === 'loading' && <Spinner />}
                {userStatus === 'success' && userData != null && <span>{userData.metadata.creationTime}</span>}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs='12' sm='4'>
            <h4>Access Tokens</h4>
            <small>You can generate a personal access token for each application you use that needs access to the NextBuildNumber API.</small>
          </Col>
          <Col xs='12' sm='8'>
            {tokenMessage && <Alert className="text-break">{tokenMessage.message}</Alert>}
            <Form id='accessTokenForm' onSubmit={handleAccessTokenFormSubmit}>
              <FormGroup>
                <Label for='tokenName'><b>Token name</b></Label>
                <Input id='tokenName' innerRef={tokenNameRef} required/>
              </FormGroup>
              <FormGroup>
                <Label for='tokenExpDate'><b>Token expiration date</b></Label>
                <Input id='tokenExpDate' type="date" innerRef={tokenExpDateRef} />
              </FormGroup>
            </Form>
            <Button type='submit' form='accessTokenForm' color='primary' disabled={tokenButtonDisabled}>{tokenButtonTitle}</Button>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs="12" sm={{offset: 4, size: 8}}>
            <hr />
            <div><b>Active access tokens</b></div>
            <Table>
              <thead>
                <tr>
                  <th>Token name</th>
                  <th>Created</th>
                  <th>Expires</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {tokensStatus === 'success' && tokensData.map(t => <tr key={t.id}>
                  <td>{t.name}</td>
                  <td>{t.createdDate?.toDate().toLocaleDateString()}</td>
                  <td>{t.expDate ? (t.expDate as Timestamp).toDate().toLocaleDateString() : ''}</td>
                  <td><Button color="light" onClick={(event) => handleTokenDelete(event, t.id)}><i className="bi bi-trash"></i></Button></td>
                </tr>)}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
