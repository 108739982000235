import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from './routes/home';
import AdminBilling from './routes/admin-billing';
import AdminProduct from './routes/admin-product';
import AdminProducts from './routes/admin-products';
import AdminRoot from './routes/admin-root';
import AdminSettings from './routes/admin-settings';
import ErrorPage from './error-page';
import SignIn from './routes/signin';
import SignUp from './routes/signup';

import { getAuth } from 'firebase/auth'; // Firebase v9+
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';
import { FirebaseAppProvider, AnalyticsProvider, FirestoreProvider, AuthProvider, useFirebaseApp, StorageProvider, FunctionsProvider } from 'reactfire';
import { SnackbarProvider } from 'notistack';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyCjDEpi41Yl_t2WfBH7zphhrwbvCCoTjDM",
  authDomain: "nextbuildnumber.firebaseapp.com",
  projectId: "nextbuildnumber",
  storageBucket: "nextbuildnumber.appspot.com",
  messagingSenderId: "808696386724",
  appId: "1:808696386724:web:d51840ddc64b3e002f0231",
  measurementId: "G-FZGD46XV5B"
};

function FirebaseWrapper() {
  const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`
  const firestore = getFirestore(app);
  const analytics = getAnalytics(app);
  const auth = getAuth(app);
  const functions = getFunctions(app, "europe-west1");
  const storage = getStorage(app);

  const router = createBrowserRouter([
    {
      element: <AdminRoot />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/products",
          element: <AdminProducts />,
        },
        {
          path: "/products/:id",
          element: <AdminProduct />,
        },
        {
          path: "/settings",
          element: <AdminSettings />,
        },
        {
          path: "/billing",
          element: <AdminBilling />,
        },
      ]
    },
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/signup",
      element: <SignUp />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/signin",
      element: <SignIn />,
      errorElement: <ErrorPage />,
    },
  ]);
  
  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestore}>
        <StorageProvider sdk={storage}>
          <AnalyticsProvider sdk={analytics}>
            <FunctionsProvider sdk={functions}>
              <SnackbarProvider maxSnack={3}>
                <RouterProvider router={router} />
              </SnackbarProvider>
            </FunctionsProvider>
          </AnalyticsProvider>
        </StorageProvider>
      </FirestoreProvider>
    </AuthProvider>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}><FirebaseWrapper/></FirebaseAppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
