import { collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import { useAuth, useFirestore } from "reactfire";
import { Button, Container, Form, FormGroup, FormText, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ProductList from "../components/product-list";
import BuildNumberTypes from "../models/build-number-types";

export default function AdminProducts() {
  const auth = useAuth();
  const firestore = useFirestore();
  const snack = useSnackbar();

  const [isNewProductModalOpen, setIsNewProductModalOpen] = useState(false);
  const productNameRef = useRef<HTMLInputElement>(null);
  const buildNumberTypeRef = useRef<HTMLInputElement>(null);
  const buildNumberInitialValueRef = useRef<HTMLInputElement>(null);
  const versionTypeRef = useRef<HTMLInputElement>(null);
  const versionInitialValueRef = useRef<HTMLInputElement>(null);

  const addProductButtonOnClick = () => {
    setIsNewProductModalOpen(true);
  };

  const toggleNewProductModal = () => setIsNewProductModalOpen(!isNewProductModalOpen);

  const handleBuildNumberTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const buildNumberType = BuildNumberTypes[event.target.value];
    console.log(buildNumberType.getCurrentValue());
  };

  const handleNewProductFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const productRef = doc(collection(firestore, 'products'));
    setDoc(productRef, {
      "id": productRef.id,
      "name": productNameRef.current?.value,
      "buildNumberType": buildNumberTypeRef.current?.value,
      "buildNumberInitialValue": buildNumberInitialValueRef.current?.value,
      "versionType": versionTypeRef.current?.value,
      "versionInitialValue": versionInitialValueRef.current?.value,
      "createdDate": serverTimestamp(),
      "userId": auth.currentUser?.uid,
    }).then(() => {
      snack.enqueueSnackbar('New Product has been created successfully', {variant: 'success'});
      setIsNewProductModalOpen(false);
    }).catch((error) => {
      snack.enqueueSnackbar('Failed to create new Product: ' + error.message, {variant: 'error'});
    });
  };

  return (
    <>
      <Container className=''>
        <div className='hstack gap-3 mt-5'>
          <h1 className='me-auto'>Products</h1>
          <Button color="primary" onClick={addProductButtonOnClick}>Add new Product</Button>
        </div>
        <hr />
        <ProductList />
      </Container>
      <Modal isOpen={isNewProductModalOpen} backdrop={true}>
        <ModalHeader toggle={toggleNewProductModal}>Add new Product</ModalHeader>
        <ModalBody>
          <Form id='newProductForm' onSubmit={handleNewProductFormSubmit}>
            <FormGroup>
              <Label for='productName'>Name</Label>
              <Input type='text' id='productName' innerRef={productNameRef} required />
            </FormGroup>
            <FormGroup>
              <Label for='buildNumberType'>Build number type</Label>
              <Input type='select' id='buildNumberType' innerRef={buildNumberTypeRef} onChange={handleBuildNumberTypeChange} required>
                {Object.entries(BuildNumberTypes).map(([key, value]) => {
                  return <option value={key} key={key}>{value.name}</option>
                })}
              </Input>
              <FormText>Click here for the details of Build Number types</FormText>
            </FormGroup>
            <FormGroup>
              <Label for='buildNumberInitialValue'>Initial value for Build number</Label>
              <Input type='text' id='buildNumberInitialValue' innerRef={buildNumberInitialValueRef} />
            </FormGroup>
            <FormGroup>
              <Label for='versionType'>Product version type</Label>
              <Input type='select' id='versionType' innerRef={versionTypeRef} required>
                <option value='semver'>SemVer</option>
              </Input>
              <FormText>Click here for the details of Product version types</FormText>
            </FormGroup>
            <FormGroup>
              <Label for='versionInitialValue'>Initial value for Product version</Label>
              <Input type='text' id='versionInitialValue' innerRef={versionInitialValueRef} />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button type='submit' form='newProductForm'>Add Product</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
