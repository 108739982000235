import { collection, doc, limit, orderBy, query, serverTimestamp, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFirestore, useFirestoreDocData, useFirestoreCollectionData, useFunctions } from "reactfire";
import { Alert, Breadcrumb, BreadcrumbItem, Button, Col, Container, Form, FormGroup, Input, Label, Row, Spinner, Table } from "reactstrap";
import buildNumberTypes from "../models/build-number-types";

export default function AdminProduct() {
  const urlParams = useParams();
  const firestore = useFirestore();
  const functions = useFunctions();
  const snack = useSnackbar();
  const navigate = useNavigate();

  const [incrementBuildNumberButtonDisabled, setIncrementBuildNumberButtonDisabled] = useState(false);
  const productDocRef = doc(firestore, 'products', urlParams.id!);
  const {status: productStatus, data: productData} = useFirestoreDocData(productDocRef);
  const {status: buildNumbersStatus, data: buildNumbers} = useFirestoreCollectionData(query(collection(firestore, 'products', urlParams.id!, 'buildNumberValues'), orderBy("createdDate", "desc"), limit(10)));

  const productNameRef = useRef<HTMLInputElement>(null);

  const handleProductFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateDoc(productDocRef, {
      'name': productNameRef.current?.value,
      'updatedDate': serverTimestamp(),
    }).then(() => {
      snack.enqueueSnackbar('Profile has been updated successfully', {variant: 'success'});
    }).catch(() => {
      snack.enqueueSnackbar('Failed to update your Profile', {variant: 'error'});
    });
  };

  const handleIncrementBuildNumberButtonClick = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIncrementBuildNumberButtonDisabled(true);
    const incrementBuildNumber = httpsCallable(functions, "incrementBuildNumber");
    incrementBuildNumber({
      productId: productDocRef.id
    }).then((result: any) => {
      snack.enqueueSnackbar('Build number has been increased successfully to ' + result.data, {variant: 'success'});
    }).catch((error) => {
      snack.enqueueSnackbar('Failed to increase Build number: ' + error.message, {variant: 'error'});
    }).finally(() => {
      setIncrementBuildNumberButtonDisabled(false);
    });
  }

  if (productStatus === 'loading') {
    return (<Container className='text-center'><Spinner>Loading Product details...</Spinner></Container>);
  } else if (productStatus === 'error') {
    return (<Container className='text-center'><Alert color='danger'>Failed to load Product details</Alert></Container>);
  } else {
    return (
      <Container className='mt-5'>
        <Breadcrumb>
          <BreadcrumbItem><Link to={'/'}>Home</Link></BreadcrumbItem>
          <BreadcrumbItem><Link to={'..'} relative='path'>Products</Link></BreadcrumbItem>
          <BreadcrumbItem>{productData.name || 'Product'}</BreadcrumbItem>
        </Breadcrumb>
        <div className='hstack gap-3'>
          <h1 className='me-auto'>{productData.name || 'Product'}</h1>
          <Button onClick={() => navigate(-1)}>Back to Product list</Button>
        </div>
        <hr />
        <Container>
          <Row>
            <Col xs='12' sm='4'>
              <h4>Product data</h4>
              <small>These data are read-only and cannot be changed once a Product has been initialized</small>
            </Col>
            <Col xs='12' sm='8'>
              <Row className='mb-2'>
                <Col sm='4'><b>Product ID</b></Col>
                <Col sm='8'>{productData.id}</Col>
              </Row>
              <Row className='mb-2'>
                <Col sm='4'><b>Build number type</b></Col>
                <Col sm='8'>{buildNumberTypes[productData.buildNumberType].name}</Col>
              </Row>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col xs='12' sm='4'>
              <h4>Main settings</h4>
            </Col>
            <Col xs='12' sm='8'>
              <Form id='productForm' onSubmit={handleProductFormSubmit}>
                <FormGroup>
                  <Label for='fullName'><b>Product name</b></Label>
                  <Input id='fullName' defaultValue={productData.name} innerRef={productNameRef} autoFocus />
                </FormGroup>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col><Button type='submit' form='productForm' color='primary'>Save</Button></Col>
          </Row>
          <Row className='mt-5'>
            <Col xs='12' sm='4'>
              <h4>Build number history</h4>
              <small>Last 10 Build numbers</small>
            </Col>
            <Col xs='12' sm='8'>
              {buildNumbersStatus === 'error' && <div>ERROR</div>}
              <Table striped size="sm">
                <thead>
                  <tr>
                    <th>Build number</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                {buildNumbersStatus === 'success' && buildNumbers.length !== 0 && buildNumbers.map(p => <tr key={p.createdDate}>
                  <td>{p.value}</td>
                  <td>{p.createdDate.toDate().toLocaleString()}</td>
                </tr>)}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col><Button color='primary' onClick={handleIncrementBuildNumberButtonClick} disabled={incrementBuildNumberButtonDisabled}>
              {incrementBuildNumberButtonDisabled && <Spinner size="sm"></Spinner>} Increment Build Number</Button></Col>
          </Row>
        </Container>
      </Container>
    );
  }
};
